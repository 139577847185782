CMS = CMS || {};

const formConfirmSubmission = () => {
  const loads = ["add_form_submit_button_event_listener"];

  const addFormSubmitButtonEventListener = () => {
    const modalBg = document.getElementById("js-modal");
    const openModalBtn = document.getElementById("js-open-confirmation-modal");
    const closeModalBtn = document.getElementById("js-close-confirmation-modal");
    const modalConfirmLink = document.getElementById("js-link-confirmation");

    // click fake save button to open confirmation modal
    if (null !== openModalBtn) {
      openModalBtn.addEventListener("click", showModalClick);
    }

    // click modal cancel to close
    if (null !== closeModalBtn) {
      closeModalBtn.addEventListener("click", hideModalClick);
    }

    // click modal background to close
    if (null !== modalBg) {
      modalBg.addEventListener("click", hideModalClick);
    }

    if (null !== modalConfirmLink) {
      modalConfirmLink.addEventListener("click", hideModalGoHrefClick);
    }

    // press escape to close
    window.addEventListener("keydown", function (event) {
      if (event.key === "Escape") {
        hideModalEsc();
      }
    });
  };

  // show modal
  const showModalClick = (event) => {
    const confirmationModal = document.getElementById("js-modal");
    confirmationModal.classList.remove("hidden");
    // prevent button from submitting form
    event.preventDefault();
  };

  const hideModalGoHrefClick = (event) => {
    const confirmationModal = document.getElementById("js-modal");
    const modalConfirmHref = event.href;

    // prevent firing unless target is clicked
    if (event.currentTarget === event.target) {
      confirmationModal.classList.add("hidden");

      // check if href exists then go when link is clicked
      if (modalConfirmHref) {
        window.location = modalConfirmHref;
      }

      // prevent firing modalBg event when cancel button is clicked
      event.stopPropagation();
    }
  };

  // click target to hide modal
  const hideModalClick = (event) => {
    const confirmationModal = document.getElementById("js-modal");

    // prevent firing when inert children (e.g. modal content) are clicked
    if (event.currentTarget === event.target) {
      confirmationModal.classList.add("hidden");
      // prevent firing modalBg event when cancel button is clicked
      event.stopPropagation();
      // prevent cancel button from submitting form
      event.preventDefault();
    }
  };

  // press escape to hide modal
  const hideModalEsc = () => {
    const confirmationModal = document.getElementById("js-modal");
    if (confirmationModal) {
      confirmationModal.classList.add("hidden");
      return;
    }
    // Component Instance
    const componentModal = document.getElementById("js-component-instance-configuration-overlay");
    if (componentModal) {
      componentModal.classList.remove("active");
    }
  };

  return {
    loads: loads,
    add_form_submit_button_event_listener: addFormSubmitButtonEventListener,
  };
};

CMS.form_confirm_submission = formConfirmSubmission();
