$(document).on('ajax:success', '.toggle_status[data-context="stock_card_component"]', function (event) {
  const $button = $(this);
  const data = event.detail[0];
  const component = data.component;
  const conflictingComponentId = component.conflicting_component_id; // Extract conflicting component ID

  // Update the clicked component's state
  if (component.active) {
    $button.removeClass('active-false').addClass('active-true');
  } else {
    $button.removeClass('active-true').addClass('active-false');
  }

  // If there's a conflicting component, find and deactivate it
  if (conflictingComponentId) {
    const $conflictingToggle = $(`.toggle_status[data-id="${conflictingComponentId}"]`);
    if ($conflictingToggle.length) {
      $conflictingToggle.removeClass('active-true').addClass('active-false');
    }
  }
});
