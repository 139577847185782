CMS = CMS || {};

const automaticallyInitiateChoicesText = () => {
  const loads = ["initiate_choices_text_on_page_load"];

  const initiateChoicesTextOnPageLoad = () => {
    let textWrapper = document.querySelectorAll(".js-automatically-initiated-choices-text-wrapper");

    if (!textWrapper.length) return false;

    textWrapper.forEach(async (element) => {
      await CMS.choices.create_choices_text(element);
      addPlaceholderToSearchInput(element);
    });
  };

  const addPlaceholderToSearchInput = (textWrapper) => {
    const textElement = textWrapper.querySelector(".js-choices-text");
    if (!textElement) return;

    const textPlaceholder = textElement.dataset["placeholder"];
    const inputElement = textWrapper.querySelector("input.choices__input");
    if (!inputElement) return;

    inputElement.placeholder = textPlaceholder;
  };

  return {
    loads: loads,
    initiate_choices_text_on_page_load: initiateChoicesTextOnPageLoad,
  };
};

CMS.automaticallyInitiateChoicesText = automaticallyInitiateChoicesText();
